import { Button, CircularProgress, Grid, Icon } from '@mui/material'
import { styled } from '@mui/system'
import SimpleCard from 'app/components/cards/SimpleCard'
import React, { useEffect, useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Breadcrumb } from 'app/components'
import { Span } from 'app/components/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { addUser } from 'app/redux/actions/UserActions'

const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}))

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

function NewUser() {
    const [state, setState] = useState({})
    const dispatch = useDispatch()

    // create a new user
    const handleSubmit = (event) => {
        dispatch(addUser(state))
    }

    const handleChange = (event) => {
        event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const { username, email, password, repeatPassword } = state

    const users = useSelector((store) => store.users)

    // validate the confirm password form
    // useEffect(() => {
    //     ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    //         if (value !== state.password && value !== state.confirmPassword) {
    //             return false
    //         }
    //         return true
    //     })
    //     return () => ValidatorForm.removeValidationRule('isPasswordMatch')
    // }, [state.password])

    return (
        <Container>
            <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'New user', path: '/new-user' }]}
                />
            </div>
            <SimpleCard title="Create New User">
                <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
                    <Grid container spacing={6}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                            <TextField
                                type="text"
                                name="username"
                                validators={['required']}
                                label="User Name"
                                errorMessages={['this field is required']}
                                onChange={handleChange}
                                value={username || ''}
                            />
                            <TextField
                                label="Email"
                                type="email"
                                name="email"
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    'this field is required',
                                    'email is not valid',
                                ]}
                                onChange={handleChange}
                                value={email || ''}
                            />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                            <TextField
                                label="Password"
                                type="password"
                                name="password"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                onChange={handleChange}
                                value={password || ''}
                            />
                            <TextField
                                label="Confirm Password"
                                name="repeatPassword"
                                type="password"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                onChange={handleChange}
                                value={repeatPassword || ''}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={users.loading}
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        {!users.loading ? (
                            <Icon>send</Icon>
                        ) : (
                            <CircularProgress size={25} color="grey" />
                        )}
                        <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
                            Submit
                        </Span>
                    </Button>
                    <p
                        style={{
                            marginLeft: '10px',
                            fontSize: '80%',
                            color: 'red',
                        }}
                    >
                        {users.error}
                    </p>
                </ValidatorForm>
            </SimpleCard>
        </Container>
    )
}

export default NewUser
