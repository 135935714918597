const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
    signals: [],
    stopLoss: [],
    takeProfit: [],
    livePrice: [],
    signal: '',
    loading: false,
    error: '',
}

const SignalsSlice = createSlice({
    name: 'Signals',
    initialState,
    reducers: {
        fetchSignalsRequest(state) {
            state.loading = true
        },
        fetchSignalsSuccess(state, action) {
            state.loading = false
            state.signals = action.payload
            state.error = ''
        },
        fetchSignalsFail(state, action) {
            state.loading = false
            state.error = action.payload
        },
        fetchOneSignalSuccess(state, action) {
            state.loading = false
            state.signal = action.payload
        },
        fetchStopLossLevelUpdate(state, action) {
            state.loading = false
            state.stopLoss.sl_level = action.payload.stopLoss.sl_level
        },
        fetchLivePriceRequest(state, action) {
            state.loading = true
        },
        fetchLivePriceSuccess(state, action) {
            state.loading = false
            state.livePrice = action.payload
        },
        fetchLivePriceFail(state, action) {
            state.loading = false
            state.error = action.payload
        },
    },
})

export const SignalsAction = SignalsSlice.actions
export const SignalsReducer = SignalsSlice.reducer
export default SignalsSlice
