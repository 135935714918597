import useAuth from 'app/hooks/useAuth'
import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
const getUserRoleAuthStatus = (user) => {
    if (!user) return false
    if (user.role === 'admin' || user.role === 'analyst') {
        return true
    } else {
        return false
    }
}

const AuthGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth()

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    // const routes = flat(AllPages())

    const isUserRoleAuthenticated = getUserRoleAuthStatus(user)
    let authenticated = isAuthenticated && isUserRoleAuthenticated

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])

    if (authenticated) return <>{children}</>
    else {
        return (
            <Navigate
                to="/session/signin"
                state={{ redirectUrl: previouseRoute }}
            />
        )
    }
}

export default AuthGuard
