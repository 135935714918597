import React, { useEffect, useState } from 'react'
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Icon,
    TableRow,
    Checkbox,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material'
import './table.css'
import { Box, styled } from '@mui/system'
import Autorenew from '@mui/icons-material/Autorenew';
import { useDispatch } from 'react-redux'
import {
    addUserToEdit,
    toggleUsersActivity,
} from 'app/redux/actions/UserActions'
import { useNavigate } from 'react-router-dom'
import { Button } from 'bootstrap'
import {BsTrash} from 'react-icons/bs';
import { deleteSignals } from 'app/redux/actions/SignalsActions';
import { AutorenewOutlined } from '@material-ui/icons';

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
            },
        },
    },
}))

function TableContent({
    onlyDelete,
    columns,
    data,
    checkedButtons,
    setCheckedButtons,
    changeStatusAction,
    selectAll,
    setSelectAll,
    total,
    edit_btn,
    edit_route,
    editStatus,
    setOpenPopUp,
    editElement,
    haveId,
    notify,
    setOpenNotify,
    setNotfiyId,
    view,
    view_route,
    viewElement,
    nothing,
    noCheckBoxes,
}) {
    const dispatch = useDispatch();
    const [dialog, setDialog] = useState(false)
    const [deletedElement, setDeletedElement] = useState(null)
    const [userToChange, setUserToChange] = useState(null);
    const [id, setId] = useState(1)
    const navigate = useNavigate()

    // add and remove the check button from the check list
    const handleCheckButton = (id) => {
        if (!checkedButtons.find((e) => e === id))
            setCheckedButtons([...checkedButtons, id])
        else setCheckedButtons((buttons) => buttons.filter((b) => b !== id))
    }

    // check if the given id is checked by checking if it is in the list of checked buttons
    const isChecked = (id) => {
        return checkedButtons.find((btn) => btn === id)
    }

    useEffect(() => {
        if (selectAll) {
            // make all the boxes checked
            let array = []
            data?.forEach((element) => array.push(element.user_id))
            setCheckedButtons(array)
        }
    }, [data, selectAll])

    // contruct the edit route
    const editUserHandler = (id) => {
        const element = data?.find((element) => element.user_id === id)
        editElement(element)
        if (edit_route != 'true') {
            navigate(edit_route)
        } else {
            setOpenPopUp(true)
        }
    }

    const viewSignalHandler = (id) => {
        const element = data.find((element)=> element.b2b_signal_id === id)
        viewElement(element.b2b_signal_id)
        if (view_route !== 'true') {
            navigate(view_route)
        } else {
            setOpenPopUp(true)
        }
    }

    if (total === 0) return <></>

    return (
        <Box className="box">
            {dialog && <Dialog
                open={editStatus}
                onClose={null}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    style={{ textAlign: 'center' }}
                    id="alert-dialog-title"
                >
                    {'Are you Sure?'}
                </DialogTitle>
                <DialogContent>
                    <div className="dialog_buttons">
                        <button onClick={() => setDialog(false)}>Cancel</button>
                        <button
                            onClick={() => {
                                changeStatusAction(userToChange)
                                setDialog(false)
                            }}
                            autoFocus
                        >
                            Change Status
                        </button>
                    </div>
                </DialogContent>
            </Dialog>}
            <StyledTable>
                <TableHead>
                    <TableRow>
                        {!noCheckBoxes && (
                            <TableCell width={50}>
                                {!selectAll &&
                                checkedButtons.length !== 0 &&
                                checkedButtons.length !== total ? (
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={() => setCheckedButtons([])}
                                        indeterminate
                                    />
                                ) : (
                                    <Checkbox
                                        checked={
                                            checkedButtons.length === total ||
                                            selectAll
                                        }
                                        onChange={() => {
                                            if (selectAll) setCheckedButtons([])
                                            setSelectAll(!selectAll)
                                        }}
                                    />
                                )}
                            </TableCell>
                        )}

                        {haveId && (
                            <TableCell>
                                <span className="row_span">Id</span>
                            </TableCell>
                        )}
                        {Object.keys(columns).map((col, i) => (
                            <TableCell key={i}>
                                <span className="row_span">
                                    {columns[col].label}
                                </span>
                            </TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody style={{ textTransform: 'lowercase' }}>
                    {data?.map((element, index) => (
                        <TableRow key={index}>
                            {!noCheckBoxes && (
                                <TableCell align="left">
                                    <Checkbox
                                        checked={
                                            isChecked(element.user_id)
                                                ? true
                                                : false
                                        }
                                        onChange={() =>
                                            handleCheckButton(element.user_id)
                                        }
                                    />
                                </TableCell>
                            )}
                            {haveId && (
                                <TableCell>
                                    <span className="row_span">
                                        {index + 1}
                                    </span>
                                </TableCell>
                            )}
                            {Object.keys(columns).map((col, i) => (
                                <TableCell align="left" key={i} >
                                    {columns[col].value
                                        ? columns[col].value(element)
                                        : element[col]}
                                </TableCell>
                            ))}
                            {!nothing &&
                                (!onlyDelete ? (
                                    <TableCell>
                                        {edit_btn && <button
                                            onClick={() =>
                                                editUserHandler(element.user_id)
                                            }
                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                            tabIndex="0"
                                            type="button"
                                        >
                                            <span
                                                className="material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-1jgtvd5"
                                                aria-hidden="true"
                                            >
                                                edit
                                            </span>
                                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                        </button>}
                                        {view && (
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                                tabIndex="0"
                                                type="button"
                                                onClick={() =>
                                                    viewSignalHandler(
                                                        element.b2b_signal_id
                                                    )
                                                }
                                            >
                                                <span
                                                    className="material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-1jgtvd5"
                                                    aria-hidden="true"
                                                >
                                                    arrow_right_alt
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                            </button>
                                        )}
                                        {
                                            editStatus && (
                                            <button
                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium jss610 css-1yxmbwk"
                                            tabIndex="0"
                                            type="button"
                                            aria-label="Delete Selected Rows"
                                        >
                                            <Autorenew
                                                onClick={() => {
                                                    setDialog(true);
                                                    setUserToChange(
                                                        element.user_id
                                                    )
                                                }}
                                            />
                                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                        </button>
                                            )
                                        }
                                    </TableCell>
                                ) : !notify ? (
                                    <TableCell>
                                        <button
                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium jss610 css-1yxmbwk"
                                            tabIndex="0"
                                            type="button"
                                            aria-label="Delete Selected Rows"
                                        >
                                            <BsTrash
                                                onClick={() => {
                                                    setDialog(true)
                                                    setDeletedElement(
                                                        element.b2b_signal_id.replace("#", "%23")
                                                    )
                                                }}
                                            />
                                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                        </button>
                                    </TableCell>
                                ) : (
                                    <TableCell>
                                        <button
                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium jss610 css-1yxmbwk"
                                            tabIndex="0"
                                            type="button"
                                            aria-label="Delete Selected Rows"
                                        >
                                            <BsTrash
                                                onClick={() => {
                                                    setDialog(true)
                                                   setDeletedElement(
                                                    element.b2b_signal_id.replace("#", "%23")
                                                    )
                                                }}
                                            />
                                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                        </button>
                                        {edit_btn && <button
                                            onClick={(id) => {
                                                setOpenNotify(true)
                                                setNotfiyId(element.user_id)
                                            }}
                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                            tabIndex="0"
                                            type="button"
                                        >
                                            <span
                                                className="material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-1jgtvd5"
                                                aria-hidden="true"
                                            >
                                                message
                                            </span>
                                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                        </button>}
                                        {view && (
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                                tabIndex="0"
                                                type="button"
                                            >
                                                <span
                                                    className="material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium css-1jgtvd5"
                                                    aria-hidden="true"
                                                >
                                                    arrow_right_alt
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                            </button>
                                        )}
                                    </TableCell>
                                ))}
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </Box>
    )
}

export default TableContent
