import Signals from './Signals'
import NewSignal from './NewSignal'
import { authRoles } from 'app/auth/authRoles'
import ViewSignal from './ViewSignal'

const SignalRoutes = [
    {
        path: '/signals',
        element: <Signals />,
        auth: authRoles.admin,
    },
    {
        path: '/create',
        element: <NewSignal />,
        auth: authRoles.admin,
    },
    {
        path: '/signal/view',
        element: <ViewSignal />,
        auth: authRoles.admin,
    },
    {
        path: '/edit-signal',
        element: <Signals />,
        auth: authRoles.admin,
    },
]

export default SignalRoutes
