import Dashboard from './Dashboard'
import React from 'react'
import { authRoles } from '../../auth/authRoles'

const dashboardRoutes = [
    {
        path: '/dashboard/default',
        element: <Dashboard />,
        auth: authRoles.hotel,
    },
]

export default dashboardRoutes
