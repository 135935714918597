import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Span } from '../Typography'
import './WelcomeBack.css'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import axios from 'axios'
import { HelpOutline } from '@material-ui/icons'
import LiveClock from '../LiveClock/LiveClock'

function WelcomeBack({ pair, setPair, setSelected, livePrice }) {
    const [assets, setAssets] = useState([])
    const [assetClass, setAssetClass] = useState(``)
    const [showBtn, setShowBtn] = useState(false)

    useEffect(async () => {
        try {
            const assets_from_db = await axios.get(
                process.env.REACT_APP_API + '/assets'
            )

            // console.log("assets",assets_from_db)

            setAssets(assets_from_db.data.assets)
        } catch (e) {
            // console.log("bob")
        }

        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",assets)

        // setAssetsss((assetsss) => [...assetsss, { id: 1, label: Assets.label }])
    }, [])

    return (
        <div>
            <div className="SignalInfoDiv">
                <div className="SignalInfo_left">
                    {/* <p className="T_name SignalInfo_margin">
                        trader:Andre Dergham
                        <span className="SignalInfo_status ">
                            Online<span className="dot"></span>
                        </span>
                    </p> */}
                    <p className="SignalInfo_AssetName SignalInfo_margin">
                        Welcome Back
                    </p>
                    <LiveClock />
                </div>
                <div className="Signal_right">
                    <div className="AssetClassAndChoosePair">
                        {assetClass && (
                            <div
                                style={{
                                    marginBottom: `0.5rem`,
                                }}
                            >
                                <small>
                                    Selected asset class: {assetClass}
                                </small>
                            </div>
                        )}
                        {assets.length > 0 && (
                            <div className="dropdown">
                                <Autocomplete
                                    onChange={(e) => {
                                        if(e.target.value === undefined){
                                            
                                            setShowBtn(false)

                                        } else {
                                            setPair(e.target.innerHTML)
                                            setShowBtn(true)
                                        }
                                        
                                    }
                                    }
                                    disablePortal
                                    id="combo-box-assets"
                                    options={assets}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Pair"
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    {pair && livePrice && showBtn &&(
                        <Button
                            // disabled={districts.loading}
                            color="primary"
                            variant="contained"
                            type="button"
                            style={{
                                // alignSelf: 'flex-end',
                                marginLeft: '1rem',
                                // marginTop: `2rem`,
                                // marginTop: '-2.5%',
                                height: '3.3rem',
                            }}
                            onClick={() => setSelected(true)}
                        >
                            <AddIcon size={25} color="grey" />
                            <Span
                                sx={{
                                    pl: 1,
                                    textTransform: 'capitalize',
                                }}
                            >
                                New Signal
                            </Span>
                        </Button>
                    )}
                </div>
            </div>
            <Button
                style={{ margin: '50px 0 0 65px' }}
                variant="contained"
                onClick={async () => {
                    await axios.post(
                        'https://b2bbackend00.herokuapp.com/assets/refresh'
                    )
                }}
            >
                Refresh Assets
            </Button>
        </div>
    )
}

export default WelcomeBack
