/** @format */

import React, { useState, useEffect } from 'react'

function LiveClock() {
    useEffect(() => {
        setInterval(() => setDateState(new Date(), 1000))
    }, [])
    const [dateState, setDateState] = useState(new Date())
    return (
        <div>
            <p className="SignalInfo_margin">
                {dateState.toLocaleDateString('en-US', {
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                    timeZoneName: 'short',
                })}
            </p>
        </div>
    )
}

export default LiveClock
