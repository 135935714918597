export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    },
    {
        label: 'PAGES',
        type: 'label',
    },
    {
        name: 'Users',
        icon: 'account_circle',
        children: [
            {
                name: 'Users List',
                iconText: 'UL',
                path: '/user',
            },
            {
                name: 'New User',
                iconText: 'NU',
                path: '/new-user',
            },
        ],
    },
    {
        name: 'Signals',
        icon: 'account_circle',
        children: [
            {
                name: 'All Signals',
                iconText: 'UL',
                path: '/signals',
            },
            {
                name: 'New Signal',
                iconText: 'NU',
                path: '/new-signal',
            }
        ],
    },
]
