import React, { useEffect, useRef, useState } from 'react'
import './cards.css';

import { Card } from '@mui/material'
import { styled, Box } from '@mui/system'
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as CSV } from './CSV.svg';
import { ReactComponent as Print } from './Print.svg';
import { ReactComponent as Columns } from './Columns.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { CSVLink } from 'react-csv';


const CardRoot = styled(Card)(() => ({
    height: '100%',
    padding: '20px 24px',
}))

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1.5rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && "16px",
}))

const SimpleCard = ({ children, title, subtitle }) => {

    return (
        <CardRoot elevation={6}>
            <div>
                <CardTitle subtitle={subtitle}>
                    {title}
                </CardTitle>
                {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
            </div>
            {children}
        </CardRoot>
    )
}

export default SimpleCard
