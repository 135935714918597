import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { ColorLensOutlined } from '@material-ui/icons'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

export const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (token) => {
    if (token) {
        sessionStorage.setItem('token', token)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
        sessionStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate()

    const login = async (email, password, username) => {
        let response = null
        response = await axios.post('/users/login', {
            email,
            password,
            username,
        })

        const token = response.data.token

        const decoded_Token = jwt_decode(token) // decoding the token to get the role of the user

        const user = {
            email: email,
            password: password,
            role: decoded_Token.role,
            username: username,
        }

        setSession(token)

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
        navigate('/')
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    const resetPassword = (email) => {
        try {
            axios.post('/resetPassword', { email })
        } catch (e) {
            // console.log(e)
        }
    }

    useEffect(() => {
        ;(async () => {
            try {
                const token = window.sessionStorage.getItem('token')
                const user = {
                    user_id: null,
                    username: null,
                    role: null,
                    email: null,
                    password: null,
                    salt: null,
                }

                if (token && isValidToken(token)) {
                    setSession(token)
                    const decoded_Token = jwt_decode(token) // decoding the token to get the role of the user
                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                    try {
                        const user_by_id = await axios.get(
                            '/users/get/' + decoded_Token.id,
                            config
                        )
                        user.email = user_by_id.data.user.email
                        user.password = user_by_id.data.user.password
                        user.role = user_by_id.data.user.role
                        user.username = user_by_id.data.user.username
                        user.user_id = user_by_id.data.user.user_id
                        user.salt = user_by_id.data.user.salt
                    } catch (e) {
                        // console.log(e)
                    }
                    // get all users and put them in an array of objects to display them later on
                    // const response = await axios.get('/users/all')
                    // // // console.log(response)
                    // const users_data = response.data.user

                    // const users = users_data.map((user) => {
                    //     const user_info = {
                    //         username: user.username,
                    //         email: user.email,
                    //         role: user.role,
                    //     }

                    //     return user_info
                    // })

                    // // console.log(users)

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
