import { Breadcrumb } from 'app/components'
import TableContent from 'app/components/TableContent'
import { ReactComponent as Search } from './../../components/cards/Search.svg'
import { ReactComponent as CSV } from './../../components/cards/CSV.svg'
import { Span } from 'app/components/Typography'
import { CSVLink } from 'react-csv'
import { TablePagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { styled, Box } from '@mui/system'
import axios from 'axios'
import WelcomeBack from 'app/components/Dashboard-welcomeback/WelcomeBack'
import DashboardRadioButtons from 'app/components/Dashboard-radioButtons/DashboardRadioButtons'
import SignalsTable from '../SignalsTable/SignalsTable'

const steps = 5

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const CardRoot = styled('div')(() => ({
    height: '100%',
    padding: '20px 24px',
}))

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1.5rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && '16px',
}))

function Dashboard() {
    const dispatch = useDispatch()
    const [checkedButtons, setCheckedButtons] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [searchedValue, setSearchedValue] = useState(null)
    const [search, setSearch] = useState(false)
    const [perPageNumbers, setPerPageNumbers] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(1)

    const columns = {
        name: {
            label: 'Name',
            value: (record) => {
                return record.name
            },
        },
    }

    const headers = Object.keys(columns).map((col) => {
        return {
            label: columns[col].label,
            key: col,
        }
    })

    // useEffect(() => {
    //     if (searchedValue !== null) {
    //         let timer = setTimeout(
    //             () => dispatch(getDistricts(1, rowsPerPage, searchedValue)),
    //             250
    //         )
    //         return () => clearTimeout(timer)
    //     }
    // }, [searchedValue])

    // useEffect(() => {
    //     let array = []
    //     let i
    //     for (i = steps; i < districts.total; i += steps) array.push(i)
    //     array.push(districts.total)
    //     setPerPageNumbers(array)
    // }, [steps, districts?.total])

    return (
        <Container>
            <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Dashboard', path: '/dashboard/default' },
                    ]}
                />
            </div>



        </Container>
    )
}

export default Dashboard
