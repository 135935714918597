//import css
import './ViewSignal.css'
import { useState, useEffect } from 'react'
import { Card } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateSignal,
    getSignal,
    uploadImage,
} from '../../redux/actions/SignalsActions'
import EditModal from 'app/components/Modals/EditModal'
const ViewSignal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const signal = useSelector((store) => store.signals?.signal)
    const [showStopLossModal, setShowSLModal] = useState(false)
    const [showTakeProfitModal, setShowTPModal] = useState(false)
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [showChartModal, setShowChartModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [showCloseSignalModal, setShowCloseSignalModal] = useState(false)
    const [showActivateSignalModal, setShowActivateSignalModal] =
        useState(false)
    const [showDescriptionModal, setShowDescriptionModal] = useState(false)
    const [showTradeFollowUpSummary, setShowTradeFollowUpSummary] =
        useState(false)

    const [editable, setEditable] = useState(true)
    // values
    const [error, setError] = useState(false)
    const [value, setValue] = useState('')
    const [textValue, setTextValue] = useState('')

    const handelValueChange = (e) => {
        if (
            parseInt(e.target.value) >= 0 &&
            !e.target.value.includes('-') &&
            !e.target.value.includes('e')
        ) {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            })
            if (error) {
                setError(false)
            }
        } else if (parseInt(e.target.value) < 0) {
            //set error for negative nums
            setValue({
                ...value,
                [e.target.name]: '',
            })
            setError(true)
        } else if (
            !e.target.value.includes('-') ||
            !e.target.value.includes('e')
        ) {
            setValue({
                ...value,
                [e.target.name]: '',
            })
        } else {
            setValue({
                ...value,
                [e.target.name]: '',
            })
        }
    }

    const handelTextValueChange = (e) => {
        setTextValue({
            ...textValue,
            [e.target.name]: e.target.value,
        })
    }

    const convertToDate = (timestamp) => {
        var date = new Date(timestamp)
        return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear()
    }

    const [imageUrl, setImageUrl] = useState('')

    const handelModal = (modal) => {
        modal = modal.toLowerCase()
        if (modal === 'stop loss') setShowSLModal(true)
        else if (modal === 'take profit') {
            setShowTPModal(true)
        } else if (modal === 'comment') {
            setShowCommentModal(true)
        } else if (modal === 'chart') {
            setShowChartModal(true)
        } else if (modal === 'status') {
            setShowStatusModal(true)
        } else if (modal === 'close signal') {
            setShowCloseSignalModal(true)
        } else if (modal === 'description') {
            setShowDescriptionModal(true)
        } else {
            setShowActivateSignalModal(true)
        }
    }
    const closeModal = (modal) => {
        if (modal === 'stop loss') {
            setShowSLModal(!showStopLossModal)
        } else if (modal === 'take profit') {
            setShowTPModal(!showTakeProfitModal)
        } else if (modal === 'comment') {
            setShowCommentModal(!showCommentModal)
        } else if (modal === 'chart') {
            setShowChartModal(!showChartModal)
        } else if (modal === 'status') {
            setShowStatusModal(!showStatusModal)
        } else if (modal === 'close signal') {
            setShowCloseSignalModal(!showCloseSignalModal)
        } else if (modal === 'description') {
            setShowDescriptionModal(!showDescriptionModal)
        } else {
            setShowActivateSignalModal(!showActivateSignalModal)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('signalID')) {
            dispatch(
                getSignal(localStorage.getItem('signalID').replace('#', '%23'))
            )
        }
    }, [])

    useEffect(() => {
        if (
            localStorage.getItem('role') === 'analyst' &&
            signal?.status === 'closed'
        ) {
            // window.location.href = '/signals';
            setEditable(false)
        }
    }, [signal])

    useEffect(() => {
        setImageUrl(
            process.env.REACT_APP_API +
                '/signals/' +
                localStorage.getItem('signalID') +
                '.jpeg'
        )
    })

    return (
        <div className="wrapper">
            <div className="left_col">
                <Card className="signal_info">
                    <div className="info_header">
                        <p>{signal?.order_type}</p>
                        <p className="market_price">{signal?.market_price}</p>
                        <hr></hr>
                    </div>
                    <div className="info_body">
                        <div className="execution_info">
                            <p>Executed at</p>
                            <p className="time">
                                {' '}
                                {convertToDate(signal?.timestamp)}{' '}
                            </p>
                        </div>
                    </div>
                </Card>
                <Card className="stop_loss_info">
                    <div className="stop_loss_header">
                        <p className="label">Stop Loss</p>
                        <p className="main_value">
                            {signal?.sl_level === null ? (
                                <span>NA</span>
                            ) : (
                                signal?.sl_level
                            )}
                        </p>
                    </div>
                </Card>
                <Card className="take_profit_info">
                    <div className="take_profit_header">
                        <p className="label">Take Profit</p>
                        <p className="main_value">
                            {signal?.tp1 === null ? (
                                <span>NA</span>
                            ) : (
                                signal?.tp1
                            )}
                        </p>
                    </div>
                    <div className="take_profit_body">
                        <ul>
                            <li>
                                {signal?.tp2 === null ? (
                                    <span>NA</span>
                                ) : (
                                    signal?.tp2
                                )}
                            </li>
                            <li>
                                {signal?.tp3 === null ? (
                                    <span>NA</span>
                                ) : (
                                    signal?.tp3
                                )}
                            </li>
                        </ul>
                    </div>
                </Card>
                <Card className="stop_loss_info">
                    <div className="stop_loss_header">
                        <p className="label">Status</p>
                        <p className="main_value">{signal?.status}</p>
                    </div>
                </Card>
                <Card>
                    <img className="ChartImg" src={imageUrl} alt="No chart" />
                </Card>
                <p>{signal?.summary === '' ? 'No comment' : signal?.summary}</p>
            </div>

            <div className="right_col">
                <Card>
                    <div className="top-info">
                        <h1 className="right_col_title">Edit Order</h1>
                        <p
                            style={{
                                marginLeft: 'auto',
                                marginTop: '4%',
                                marginRight: '3%',
                            }}
                        >
                            Signal id: {signal?.b2b_signal_id}
                        </p>
                    </div>
                    <div className="edit_btn">
                        <ul
                            onClick={() => {
                                if (editable) handelModal('chart')
                            }}
                        >
                            <li>Edit Chart</li>
                            <li>
                                <BsArrowRight className="icon" />
                            </li>
                            {showChartModal && (
                                <EditModal
                                    open={showChartModal}
                                    closeModal={() => closeModal('chart')}
                                    title="Edit Chart"
                                    Id={signal?.b2b_signal_id}
                                    label="Chart"
                                    inputType="file"
                                />
                            )}
                        </ul>
                    </div>
                    <div className="edit_btn">
                        <ul
                            onClick={() => {
                                if (editable) handelModal('stop loss')
                            }}
                        >
                            <li>Edit Stop Loss</li>
                            <li>
                                <BsArrowRight className="icon" />
                            </li>
                        </ul>

                        {showStopLossModal && (
                            <EditModal
                                open={showStopLossModal}
                                closeModal={() => {
                                    closeModal('stop loss')
                                }}
                                title="Edit Stop Loss"
                                Id={signal?.b2b_signal_id}
                                label="Stop Loss"
                                data={signal?.sl_level ? signal?.sl_level : ''}
                                inputType="number"
                                inputName={['sl_level']}
                                error={error}
                                setError={setError}
                                value={value}
                                setValue={setValue}
                                handelValueChange={handelValueChange}
                            />
                        )}
                    </div>
                    <div className="edit_btn">
                        <ul
                            onClick={() => {
                                if (editable) handelModal('take profit')
                            }}
                        >
                            <li>Edit Take Profit</li>
                            <li>
                                <BsArrowRight className="icon" />
                            </li>
                        </ul>
                        {showTakeProfitModal && (
                            <EditModal
                                open={showTakeProfitModal}
                                closeModal={() => {
                                    closeModal('take profit')
                                }}
                                title="Edit Take Profit"
                                Id={signal?.b2b_signal_id}
                                label="Take Profit"
                                data={[
                                    signal?.tp1 ? signal?.tp1 : '',
                                    signal?.tp2 ? signal?.tp2 : '',
                                    signal?.tp3 ? signal?.tp3 : '',
                                ]}
                                inputType="number"
                                inputName={['tp1', 'tp2', 'tp3']}
                                error={error}
                                setError={setError}
                                value={value}
                                setValue={setValue}
                                handelValueChange={handelValueChange}
                            />
                        )}
                    </div>

                    <div className="edit_btn">
                        <ul
                            onClick={() => {
                                {
                                    if (editable) handelModal('comment')
                                }
                            }}
                        >
                            <li>Edit Signal Summary</li>
                            <li>
                                <BsArrowRight className="icon" />
                            </li>
                            {showCommentModal && (
                                <EditModal
                                    open={showCommentModal}
                                    closeModal={() => closeModal('comment')}
                                    title="Edit Signal Summary"
                                    Id={signal?.b2b_signal_id}
                                    label="Comment"
                                    data={
                                        signal?.summary ? signal?.summary : ''
                                    }
                                    inputType="textarea"
                                    inputName="summary"
                                    value={textValue}
                                    setValue={setTextValue}
                                    handelTextValueChange={
                                        handelTextValueChange
                                    }
                                />
                            )}
                        </ul>
                    </div>
                    {signal?.status === 'pending' && (
                        <div className="edit_btn">
                            <ul onClick={() => handelModal('activate signal')}>
                                <li>Activate Order</li>
                                <li>
                                    <BsArrowRight className="icon" />
                                </li>
                                {showActivateSignalModal && (
                                    <EditModal
                                        open={showActivateSignalModal}
                                        closeModal={() =>
                                            closeModal('activate signal')
                                        }
                                        title="Activate Signal"
                                        id={signal?.b2b_signal_id}
                                        label="Are you sure you want to activate the signal?"
                                        submitAction={(id, val) => {
                                            dispatch(updateSignal(id, val))
                                            setTimeout(() => {
                                                window.location.reload()
                                            }, 1000)
                                        }}
                                    />
                                )}
                            </ul>
                        </div>
                    )}
                    <div className="edit_btn">
                        <ul
                            onClick={() => {
                                if (editable) handelModal('close signal')
                            }}
                        >
                            <li>Close Order</li>
                            <li>
                                <BsArrowRight className="icon" />
                            </li>
                            {showCloseSignalModal && (
                                <EditModal
                                    open={showCloseSignalModal}
                                    closeModal={() =>
                                        closeModal('close signal')
                                    }
                                    title="Closing Signal"
                                    id={signal?.b2b_signal_id}
                                    label="Are you sure you want to close the signal?"
                                    submitAction={(id, val) => {
                                        dispatch(updateSignal(id, val))
                                        setTimeout(() => {
                                            window.location.reload()
                                        }, 1000)
                                    }}
                                />
                            )}
                        </ul>
                    </div>
                    <div className="card_footer">
                        <Button
                            variant="contained"
                            className="submit-btn"
                            onClick={() => {
                                // console.log(value)
                                dispatch(
                                    updateSignal(signal?.b2b_signal_id, {
                                        ...value,
                                        ...textValue,
                                    })
                                )
                                setTimeout(() => {
                                    window.location.reload()
                                }, 100)
                            }}
                        >
                            Save Changes
                        </Button>
                        <Button
                            variant="contained"
                            className="submit-btn"
                            onClick={() => {
                                navigate('/signals')
                            }}
                            style={{ marginLeft: 'auto' }}
                        >
                            Go back
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default ViewSignal
