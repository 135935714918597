import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import NewUser from './newUser/NewUser'
import EditUser from './viewUser/EditUser'
import { authRoles } from 'app/auth/authRoles'
import Signals from '../Signals/Signals'
import NewSignal from '../Signals/NewSignal'

const UsersList = Loadable(lazy(() => import('./usersList/UsersList')))

const userRoutes = [
    {
        path: '/user',
        element: <UsersList />,
        auth: authRoles.admin,
    },
    {
        path: '/new-user',
        element: <NewUser />,
        auth: authRoles.admin,
    },
    {
        path: '/signals',
        element: <Signals />,
        auth: authRoles.admin,
    },
    {
        path: '/new-signal',
        element: <NewSignal />,
        auth: authRoles.admin,
    },
    {
        path: '/edit-user',
        element: <EditUser />,
        auth: authRoles.admin,
    },
]

export default userRoutes
