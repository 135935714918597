import { combineReducers } from 'redux'
import NavigationReducer from './NavigationReducer'
import { UserReducer } from './UserReducer'
import { SignalsReducer } from './SignalsReducer'
const RootReducer = combineReducers({
    navigations: NavigationReducer,
    users: UserReducer,
    signals: SignalsReducer,
})

export default RootReducer
