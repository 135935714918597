import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import './DashboardRadioButtons.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getLivePrice } from 'app/redux/actions/SignalsActions'
import { useNavigate } from 'react-router-dom'

const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
    },
}))

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup()

    let checked = false

    if (radioGroup) {
        checked = radioGroup.value === props.value
    }

    return <StyledFormControlLabel checked={checked} {...props} />
}

MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
}

export default function UseRadioGroup({
    type,
    market_price,
    onClick,
    setType,
    setMarketPrice,
    pair,
}) {
    //response.target.value get the value of the selected radio button
    const [value, setvalue] = useState('Market Buy') //this variable will be used to get the value of the checked radio button
    const [inputValue, setinputValue] = useState() //this variable will be used to get the value of the input field
    const navigate = useNavigate()
    const handleChange = (e) => {
        setvalue(e.target.value)
        setType(e.target.value)
    }

    useEffect(() => {
        setvalue(type)
    }, [value])

    useEffect(() => {
        setinputValue(market_price)
    }, [inputValue])

    const inputHandleOnChange = (e) => {
        if (e.target.value < 0) {
            e.target.value = e.target.value * -1
        }
        const timer = setTimeout(() => {
            setinputValue(e.target.value)
            setMarketPrice(e.target.value)
        }, 500)
    }

    return (
        <div className="radioButton-main">
            <div className="radio-buttons-container">
                <div className="radio-buttons-container-header">
                    <p>Select Order Type</p>
                </div>
                {/* the onchange in the radiobutton group allows us to get the value of the selected button */}
                <div className="radio-buttons-container-body">
                    <RadioGroup
                        name="use-radio-group"
                        defaultValue="Market Buy"
                        onChange={handleChange}
                    >
                        <div
                            className={`radio-button-options ${
                                value === 'Market Buy'
                                    ? 'blueBorder'
                                    : 'greyBorder'
                            }`}
                        >
                            <MyFormControlLabel
                                value="Market Buy"
                                label="Market Buy"
                                control={<Radio />}
                            />
                        </div>
                        <div
                            className={`radio-button-options ${
                                value === 'Market Sell'
                                    ? 'blueBorder'
                                    : 'greyBorder'
                            }`}
                        >
                            <MyFormControlLabel
                                value="Market Sell"
                                label="Market Sell"
                                control={<Radio />}
                            />
                        </div>
                        <div
                            className={`radio-with-input radio-button-options ${
                                value === 'Buy Limit'
                                    ? 'blueBorder'
                                    : 'greyBorder'
                            }`}
                        >
                            <MyFormControlLabel
                                value="Buy Limit"
                                label="Buy Limit"
                                control={<Radio />}
                            />
                            {value === 'Buy Limit' && (
                                <div>
                                    <label
                                        htmlFor="Buy-limit-ammount"
                                        className="radioButton-input-label"
                                    >
                                        Select Price
                                    </label>
                                    <input
                                        className="select-input"
                                        type="number"
                                        min="0"
                                        name="Buy-limit-ammount"
                                        id="Buy-limit-ammount"
                                        required
                                        onChange={inputHandleOnChange}
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className={`radio-with-input radio-button-options ${
                                value === 'Sell Limit'
                                    ? 'blueBorder'
                                    : 'greyBorder'
                            }`}
                        >
                            <MyFormControlLabel
                                value="Sell Limit"
                                label="Sell Limit"
                                control={<Radio />}
                            />
                            {value === 'Sell Limit' && (
                                <div>
                                    <label
                                        htmlFor="Sell-limit-ammount"
                                        className="radioButton-input-label"
                                    >
                                        Select Price
                                    </label>
                                    <input
                                        className="select-input"
                                        type="number"
                                        min="0"
                                        name="Sell-limit-ammount"
                                        id="Sell-limit-ammount"
                                        required
                                        onChange={inputHandleOnChange}
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className={`radio-with-input radio-button-options ${
                                value === 'Buy Stop'
                                    ? 'blueBorder'
                                    : 'greyBorder'
                            }`}
                        >
                            <MyFormControlLabel
                                value="Buy Stop"
                                label="Buy Stop"
                                control={<Radio />}
                            />
                            {value === 'Buy Stop' && (
                                <div>
                                    <label
                                        htmlFor="Buy-stop-ammount"
                                        className="radioButton-input-label"
                                    >
                                        Select Price
                                    </label>
                                    <input
                                        className="select-input"
                                        type="number"
                                        min="0"
                                        name="Buy-stop-ammount"
                                        id="Buy-stop-ammount"
                                        required
                                        onChange={inputHandleOnChange}
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className={`radio-with-input radio-button-options ${
                                value === 'Sell Stop'
                                    ? 'blueBorder'
                                    : 'greyBorder'
                            }`}
                        >
                            <MyFormControlLabel
                                value="Sell Stop"
                                label="Sell Stop"
                                control={<Radio />}
                            />
                            {value === 'Sell Stop' && (
                                <div>
                                    <label
                                        htmlFor="Sell-stop-ammount"
                                        className="radioButton-input-label"
                                    >
                                        Select Price
                                    </label>
                                    <input
                                        className="select-input"
                                        type="number"
                                        min="0"
                                        name="Sell-stop-ammount"
                                        id="Sell-stop-ammount"
                                        required
                                        onChange={inputHandleOnChange}
                                    />
                                </div>
                            )}
                        </div>
                    </RadioGroup>
                    <div className="radioButton-continue">
                        <button
                            className="radioButtonDiscard"
                            onClick={() => navigate('/')}
                        >
                            Discard Signal
                        </button>
                        {(type === 'Market Buy' || type === 'Market Sell') && (
                            <button onClick={onClick}>Continue</button>
                        )}
                        {type !== 'Market Buy' &&
                            type !== 'Market Sell' &&
                            market_price !== undefined &&
                            market_price != '' && (
                                <button onClick={onClick}>Continue</button>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}
