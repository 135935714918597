import { addSignal, getLivePrice } from 'app/redux/actions/SignalsActions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './ConfirmSignal.css'
import jwt_decode from 'jwt-decode'
import { socket } from '../../App'
import { useNavigate } from 'react-router-dom'

function ConfirmSignal({
    confirmation,
    type,
    pair,
    price,
    summary,
    orderSize,
    setSignalDetails,
    signalDetails,
}) {
    const live = useSelector((store) => store.signals.livePrice)

    const dispatch = useDispatch()

    const token = sessionStorage.getItem('token')
    const decoded_Token = jwt_decode(token)
    const [clickSendCreate, setClickSendCreate] = useState(false)

    useEffect(() => {
        if (signalDetails.user_id !== 0) {
            dispatch(addSignal(signalDetails))
        }
    }, [signalDetails])
    const navigate = useNavigate()
    const handleClick = async () => {
        await setSignalDetails({
            pair: pair,
            order_type: type,
            market_price: price,
            summary: summary,
            order_size: orderSize,
            user_id: decoded_Token.id,
            chart: 'image-path',
            trade_type: 'Intraday',
        })
    }

    return (
        <div className="confirmContainer">
            <div>
                {confirmation && (
                    <p className="confirmTitle">
                        Are You Sure You Want
                        <br />
                        To Send This Signal ?
                    </p>
                )}
                {!confirmation && (
                    <p className="confirmTitle">Congrats Your Signal Is Live</p>
                )}
            </div>
            <div className="subContainer">
                <div className="selectedPair">
                    <span className="smallTitle">Selected Price</span>
                    <p>{pair}</p>
                </div>
                <div className="orderType">
                    <span className="smallTitle">Order Type</span>
                    <p>{type}</p>
                </div>
                <div className="price">
                    <span className="smallTitle">Price</span>
                    <p>{price}</p>
                </div>
            </div>
            <div className="buttonContainer">
                <button
                    className="Discard_button"
                    onClick={() => navigate('/')}
                >
                    Discard Signal
                </button>
                {confirmation && <button onClick={()=>{
                    handleClick();
                    setClickSendCreate(true)
                    }
                } disabled={clickSendCreate}>Send</button>}
                {!confirmation && (
                    <button onClick={handleClick}>Edit Signal</button>
                )}
            </div>
        </div>
    )
}

export default ConfirmSignal
