import './EditModal.css'
import { Modal } from '@mui/material'
import { Button } from '@mui/material'
import { AiOutlineClose } from 'react-icons/ai'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { uploadImage } from 'app/redux/actions/SignalsActions'

const EditModal = (props) => {
    var uploadImg = new FormData()
    const dispatch = useDispatch()
    const handelImageChange = (e) => {
        uploadImg.append('chart', e.target.files[0])
        dispatch(uploadImage(props.Id, uploadImg))
    }

    useEffect(() => {
        if (typeof props.data === 'object') {
            let inputs = Object.values(props.inputName)
            props.setValue({
                ...props.value,
                ['tp1']: props.data[0] ? props.data[0] : '',
                ['tp2']: props.data[1] ? props.data[1] : '',
                ['tp3']: props.data[2] ? props.data[2] : '',
            })
        } else if (['number', 'string'].includes(typeof props.data)) {
            props.setValue({
                ...props.value,
                [props.inputName]: props.data,
            })
        }
    }, [])
    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modal_body_container">
                <div className="modal_header">
                    <AiOutlineClose
                        onClick={(e) => {
                            e.stopPropagation()
                            props.closeModal()
                        }}
                    />
                </div>
                <h4>{props.title}</h4>
                <form className="form">
                    <label>{props.label}</label>

                    {props.label.toLowerCase() === 'stop loss' && (
                        <div className="input_container">
                            <input
                                type={props.inputType}
                                name="sl_level"
                                id="input1"
                                value={props.value[`sl_level`]}
                                onChange={(e) => props.handelValueChange(e)}
                            />
                            {props.error && (
                                <span className="error_msg">
                                    Input can't be negative number
                                </span>
                            )}
                            <Button
                                variant="contained"
                                className="submit-btn"
                                onClick={() => props.closeModal()}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                    {props.label.toLowerCase() === 'take profit' && (
                        <div className="input_container">
                            <div className="tp_input">
                                <label htmlFor="tp1">TP 1</label>
                                <input
                                    type={props.inputType}
                                    name="tp1"
                                    id="tp0"
                                    value={props.value.tp1}
                                    onChange={(e) => props.handelValueChange(e)}
                                />
                            </div>
                            <div className="tp_input">
                                <label htmlFor="tp2">TP 2</label>
                                <input
                                    type={props.inputType}
                                    name="tp2"
                                    id="tp1"
                                    value={props.value.tp2}
                                    onChange={(e) => props.handelValueChange(e)}
                                />
                            </div>

                            <div className="tp_input">
                                <label htmlFor="tp3">TP 3</label>
                                <input
                                    type={props.inputType}
                                    name="tp3"
                                    id="tp2"
                                    value={props.value.tp3}
                                    onChange={(e) => props.handelValueChange(e)}
                                />
                            </div>
                            {props.error && (
                                <span className="error_msg">
                                    Input can't be negative number
                                </span>
                            )}
                            <Button
                                variant="contained"
                                className="submit-btn"
                                onClick={() => props.closeModal()}
                            >
                                Save
                            </Button>
                        </div>
                    )}

                    {props.label.toLowerCase() === 'comment' && (
                        <div className="input_container">
                            <textarea
                                className="textareaInput"
                                name={props.inputName}
                                key="input"
                                onChange={(e) => props.handelTextValueChange(e)}
                            >{props.value[props.inputName]}</textarea>
                            <Button
                                variant="contained"
                                className="submit-btn"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    props.closeModal()
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    )}

                    {props.label.toLowerCase() === 'chart' && (
                        <div className="input_container">
                            <form encType="multipart/form-data">
                                <input
                                    type={props.inputType}
                                    name="chart"
                                    id="chart"
                                    accept="image/*"
                                    onChange={(e) => {
                                        handelImageChange(e)
                                    }}
                                />
                            </form>
                            <Button
                                variant="contained"
                                className="submit-btn"
                                onClick={() => {
                                    window.location.reload()
                                    props.closeModal()
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    )}

                    {props.title.toLowerCase() === 'closing signal' && (
                        <div className="btn_fields">
                            <Button
                                variant="contained"
                                className="cancel-btn"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    props.closeModal()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className="submit-btn"
                                onClick={(e) => [
                                    props.submitAction(props.id, {
                                        status: 'closed',
                                    }),
                                    props.closeModal(),
                                ]}
                            >
                                Yes
                            </Button>
                        </div>
                    )}
                    {props.title.toLowerCase() === 'activate signal' && (
                        <div className="btn_fields">
                            <Button
                                variant="contained"
                                className="cancel-btn"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    props.closeModal()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className="submit-btn"
                                onClick={(e) => [
                                    props.submitAction(props.id, {
                                        status: 'active',
                                    }),
                                    props.closeModal(),
                                ]}
                            >
                                Yes
                            </Button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    )
}

export default EditModal
