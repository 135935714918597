import axios from '../../../axios'
import {
    UPDATE_USERS,
    FETCH_USERS_FAIL,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    REMOVE_LOADING,
    CLEAR_USERS,
    FETCH_ALL_USERS,
    ADD_USER_TO_EDIT,
} from './../types/userTypes'

// fetch user actions
export const fetchUserRequest = () => {
    return {
        type: FETCH_USERS_REQUEST,
    }
}

export const fetchUserSuccess = (data) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: data,
    }
}

export const fetchUserFail = (error) => {
    return {
        type: FETCH_USERS_FAIL,
        payload: error,
    }
}

export const removeLoadingAction = (error) => {
    return {
        type: REMOVE_LOADING,
    }
}

export const deleteUsersAction = (users) => {
    return {
        type: UPDATE_USERS,
        payload: users,
    }
}

export const clearUsersAction = () => {
    return {
        type: CLEAR_USERS,
    }
}

export const getAllUsersAction = (users) => {
    return {
        type: FETCH_ALL_USERS,
        payload: users,
    }
}

export const addUserToEdit = (user) => {
    return {
        type: ADD_USER_TO_EDIT,
        payload: user,
    }
}

// get all users
export const getUsers = () => async (dispatch) => {
    try {
        dispatch(fetchUserRequest())
        const res = await axios.get(`/users/all`)
        dispatch(fetchUserSuccess(res.users))
    } catch (e) {
        dispatch(fetchUserFail(e))
    }
}

// delete users given array of their ids
export const toggleUsersActivity = (id) => async (dispatch) => {
    try {
        dispatch(fetchUserRequest())
        const res = await axios.put(`/users/toggleUserActivity/${id}`)
        dispatch(deleteUsersAction(id))
        dispatch(getUsers())
        window.location.reload()
    } catch (e) {
        dispatch(fetchUserFail(e.message))
    }
}

export const addUser = (user) => async (dispatch) => {
    try {
        dispatch(fetchUserRequest())
        await axios.post(`/users/create`, user)
        dispatch(removeLoadingAction())
        window.location.href = '/user'
    } catch (e) {
        dispatch(fetchUserFail(e.message))
    }
}

export const addManager = (manager) => async (dispatch) => {
    try {
        dispatch(fetchUserRequest())
        await axios.post(`/createHotelOwner`, manager)
        dispatch(removeLoadingAction())
        window.location.href = '/managers'
    } catch (e) {
        dispatch(fetchUserFail(e.message))
    }
}

export const editUserInfo =
    (user, flag = true) =>
    async (dispatch) => {
        try {
            dispatch(fetchUserRequest())

            if (user.password === undefined) {
                await axios.put(`/users/update/${user.id}`, {
                    email: user.email,
                    username: user.username,
                })
            } else {
                await axios.put(`/users/update/${user.id}`, {
                    email: user.email,
                    username: user.username,
                    password: user.password,
                })
            }
            dispatch(removeLoadingAction())
            window.location.href = flag ? '/user' : '/managers'
        } catch (e) {
            dispatch(fetchUserFail(e.message))
        }
    }

export const changeAllActivity = () => async (dispatch) => {
    try {
        dispatch(fetchUserRequest())
        await axios.post()
        dispatch(clearUsersAction())
    } catch (e) {
        dispatch(fetchUserFail(e.message))
    }
}

export const getAllUsers = () => async (dispatch) => {
    try {
        const response = await axios.get(`/users/all`)
        // console.log('rrrrrrrrrrrrrrrrrr', response)
        dispatch(getAllUsersAction(response.data.users))
    } catch (e) {
        dispatch(fetchUserFail(e.message))
    }
}
