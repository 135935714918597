import {
    CLEAR_USERS,
    UPDATE_USERS,
    FETCH_ALL_USERS,
    FETCH_USERS_FAIL,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    REMOVE_LOADING,
    ADD_USER_TO_EDIT,
} from './../types/userTypes'

const initialState = {
    lastPage: 0,
    total: 0,
    users: [],
    allUsers: [],
    editUser: {},
    loading: false,
    error: '',
}

// delete list of users
// loop over the deleted users and delete them from the list
const toggleUsersActivity = (users, deleted) => {
    let result = []
    users.map((user) => {
        let flag = true
        deleted.forEach((deleted_user) => {
            if (deleted_user === users.user_id) flag = false
        })
        if (flag) result.push(user)
    })

    return result
}

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                lastPage: action.payload.lastPage,
                total: action.payload.total,
                error: '',
                loading: false,
            }

        case FETCH_USERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case REMOVE_LOADING:
            return {
                ...state,
                loading: false,
                error: '',
            }

        case UPDATE_USERS:
            return {
                ...state,
                users: toggleUsersActivity(state.users, action.payload),
                total: (state.total -= action.payload.length),
                loading: false,
            }

        case CLEAR_USERS:
            return {
                ...state,
                total: 0,
                loading: false,
                users: [],
            }

        case FETCH_ALL_USERS:
            return {
                ...state,
                allUsers: action.payload,
            }

        case ADD_USER_TO_EDIT:
            return {
                ...state,
                editUser: action.payload,
            }

        default:
            return state
    }
}
