export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'
export const REMOVE_LOADING = 'REMOVE_LOADING'

export const UPDATE_USERS = 'UPDATE_USERS'
export const CLEAR_USERS = 'CLEAR_USERS'

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS'
export const ADD_USER_TO_EDIT = 'ADD_USER_TO_EDIT'
