import React from 'react'
import './Signals.css'
import {
    deleteSignals,
    getSignals,
    getSignal,
} from '../../redux/actions/SignalsActions'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { Breadcrumb } from 'app/components'
import { styled } from '@mui/system'
import { ReactComponent as Search } from './../../components/cards/Search.svg'
import { ReactComponent as CSV } from './../../components/cards/CSV.svg'
import { CSVLink } from 'react-csv'
import TableContent from 'app/components/TableContent'
import { TablePagination } from '@mui/material'

function Signals() {
    const dispatch = useDispatch()
    const signalsState = useSelector((store) => store.signals)
    const [checkedButtons, setCheckedButtons] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [searchedValue, setSearchedValue] = useState(null)
    const [search, setSearch] = useState(false)
    // const [perPageNumbers, setPerPageNumbers] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [openPopUp, setOpenPopUp] = useState(false)
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))
    const CardRoot = styled('div')(() => ({
        height: '100%',
        padding: '20px 24px',
    }))
    const CardTitle = styled('div')(({ subtitle }) => ({
        fontSize: '1.5rem',
        fontWeight: '500',
        textTransform: 'capitalize',
        marginBottom: !subtitle && '16px',
    }))
    const columns = {
        b2b_signal_id: {
            label: 'Signal Id',
            value: (record) => {
                return <span>{record.b2b_signal_id.toUpperCase()}</span>
            },
        },
        status: {
            label: 'Status',
            value: (record) => {
                return <p>{record.status}</p>
            },
        },
        timestamp: {
            label: 'Execution Time',
            value: (record) => {
                return record.timestamp
            },
        },
        order_type: {
            label: 'Market Type',
            value: (record) => {
                if (record.order_type.toLowerCase() === 'market buy')
                    return (
                        <span className="market_buy">
                            {' '}
                            {record.order_type}{' '}
                        </span>
                    )
                else if (record.order_type.toLowerCase() === 'market sell') {
                    return (
                        <span className="market_sell">
                            {' '}
                            {record.order_type}{' '}
                        </span>
                    )
                } else {
                    return <span> {record.order_type} </span>
                }
            },
        },
        market_price: {
            label: 'Price',
            value: (record) => {
                return record.market_price
            },
        },
        tp1: {
            label: 'TP 1',
            value: (record) => {
                return <p>{record.tp1 === null ? 'NA' : record.tp1}</p>
            },
        },
        tp2: {
            label: 'TP 2',
            value: (record) => {
                return <p>{record.tp2 === null ? 'NA' : record.tp2}</p>
            },
        },
        tp3: {
            label: 'TP 3',
            value: (record) => {
                return <p>{record.tp3 === null ? 'NA' : record.tp3}</p>
            },
        },
        sl_level: {
            label: 'SL',
            value: (record) => {
                return (
                    <p>{record.sl_level === null ? 'NA' : record.sl_level}</p>
                )
            },
        }
    }

    const headers = Object.keys(columns).map((col) => {
        return {
            label: columns[col].label,
            key: col,
        }
    })
    useEffect(() => {
        dispatch(getSignals());
    }, []);


    return (
        <Container>
            <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Signals', path: '/Signals' }]}
                />
            </div>
            <div className="table-box">
                {/* header part */}
                <CardRoot elevation={6}>
                    {checkedButtons.length === 0 ? (
                        <div className="table_header">
                            {!search && <CardTitle> Signals </CardTitle>}
                            {search && (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                    }}
                                    className="search_input"
                                    key={'searchContainer7'}
                                >
                                    <Search />
                                    <input
                                        type="text"
                                        key={'searchInput32'}
                                        onChange={(e) =>
                                            setSearchedValue(e.target.value)
                                        }
                                        placeholder="search here"
                                    />
                                    <button
                                        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                        tabIndex="0"
                                        type="button"
                                        onClick={() => setSearch(false)}
                                    >
                                        <span
                                            class="material-icons notranslate MuiIcon-root MuiIcon-fontSizeSmall css-15375wz"
                                            aria-hidden="true"
                                        >
                                            clear
                                        </span>
                                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                                    </button>
                                </form>
                            )}
                            <div className="table_icons">
                                <button
                                    className="MuiButtonBase-root jss1297 MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                    onClick={() => setSearch(!search)}
                                >
                                    <Search />
                                </button>
                                <button className="MuiButtonBase-root jss1297 MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk">
                                    {
                                        <CSVLink
                                            data={signalsState?.signals}
                                            headers={headers}
                                        >
                                            <CSV />
                                        </CSVLink>
                                    }
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="delete_table_rows">
                            <p>
                                {selectAll
                                    ? signalsState.total
                                    : checkedButtons.length}{' '}
                                row(s) selected
                            </p>
                            <button
                                class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium jss610 css-1yxmbwk"
                                tabIndex="0"
                                type="button"
                                aria-label="Delete Selected Rows"
                            >
                                <span class="MuiTouchRipple-root css-w0pj6f"></span>
                            </button>
                        </div>
                    )}
                </CardRoot>
                <TableContent
                    haveId={false}
                    notify={true}
                    noCheckBoxes={true}
                    setCheckedButtons={setCheckedButtons}
                    checkedButtons={checkedButtons}
                    columns={columns}
                    data={signalsState.signals.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                    )}
                    setSelectAll={setSelectAll}
                    selectAll={selectAll}
                    view={true}
                    view_route={'/signal/view'} // include id in route
                    viewElement={(element) => {
                        dispatch(getSignal(element.replace('#', '%23')))
                    }}
                    edit={false}
                    setOpenPopUp={setOpenPopUp}
                />
                <TablePagination
                    component="div"
                    count={signalsState.signals.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Container>
    )
}

export default Signals
