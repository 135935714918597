import axios from '../../../axios'
import { SignalsAction } from '../reducers/SignalsReducer'
import { socket } from '../../App'
import jwt_decode from 'jwt-decode'

export const getSignals = () => async (dispatch) => {
    dispatch(SignalsAction.fetchSignalsRequest())
    try {
        const res = await axios.get('/signals/')
        // console.log(res)
        dispatch(SignalsAction.fetchSignalsSuccess(res.data.signals))
    } catch (e) {
        // console.log(e)
        dispatch(SignalsAction.fetchSignalsFail(e))
    }
}

export const deleteSignals = (id) => async (dispatch) => {
    try {
        await axios.delete(`/signals/${id}/delete`)
        dispatch(getSignals())
    } catch (e) {
        dispatch(SignalsAction.fetchSignalsFail(e))
    }
}

export const addSignal = (signalDetails) => async (dispatch) => {
    try {
        // console.log("Details",signalDetails)
        await axios.post('/signals/create/', {
            asset_name: signalDetails.pair,
            order_type: signalDetails.order_type,
            market_price: signalDetails.market_price,
            chart: signalDetails.chart,
            user_id: signalDetails.user_id,
            trade_type: signalDetails.trade_type,
        })
        window.location.href = '/'

        socket.send('signal_created')
    } catch (e) {
        dispatch(SignalsAction.fetchSignalsFail(e))
    }
}

export const getSignal = (id) => async (dispatch) => {
    try {
        dispatch(SignalsAction.fetchSignalsRequest())
        localStorage.removeItem('signalID', id)
        const response = await axios.get(`/signals/get/${id}`)
        dispatch(SignalsAction.fetchOneSignalSuccess(response.data.signal))
        localStorage.setItem('signalID', id)
    } catch (e) {
        // console.log(e)
        dispatch(SignalsAction.fetchSignalsFail(e.response))
    }
}

export const updateSignal = (id, data) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log('updated')
            socket.send('signal_updated')
            // console.log(data)
            const tokenn = sessionStorage.getItem('token')
            const decoded_Tokenn = jwt_decode(tokenn)
            const user_id = decoded_Tokenn.id
            const is_admin = decoded_Tokenn.role == 'admin' ? 1 : 0

            data['user_id'] = user_id
            data['is_admin'] = is_admin

            dispatch(SignalsAction.fetchSignalsRequest())
            const response = await axios.put(
                `/signals/update/${id.replace('#', '%23')}`,
                data
            )
            dispatch(SignalsAction.fetchOneSignalSuccess(response.data.message))
            resolve(response.data.message)
        } catch (e) {
            // console.log(e)
            dispatch(SignalsAction.fetchSignalsFail(e.response))
        }
    })
}

export const getLivePrice = (pair) => async (dispatch) => {
    dispatch(SignalsAction.fetchLivePriceRequest())
    try {
        const res = await axios.get(`/assets/live/` + pair)
        dispatch(SignalsAction.fetchLivePriceSuccess(res.data))
        // console.log(res.data)
    } catch (e) {
        dispatch(SignalsAction.fetchLivePriceFail(e))
    }
}

export const updateSignalStatus = (id, data) => async (dispatch) => {
    try {
        dispatch(SignalsAction.fetchSignalsRequest())
        const response = await axios.patch(`/signals/${id}/update`, data)
        dispatch(SignalsAction.fetchOneSignalSuccess(response.data.signal))
    } catch (e) {
        dispatch(SignalsAction.fetchSignalsFail(e.response))
    }
}

export const uploadImage = (id, data) => async (dispatch) => {
    try {
        // console.log('image uploaded')
        // console.log(id, data)
        dispatch(SignalsAction.fetchSignalsRequest())
        const response = await axios.post(
            `/signals/chart/${id.replace('#', '%23')}`,
            data
        )
        dispatch(SignalsAction.fetchOneSignalSuccess(response.data.signal))
        // // console.log(id, data)
    } catch (e) {
        // console.log(e.response)
        dispatch(SignalsAction.fetchSignalsFail(e.response))
    }
}
