import { Button } from '@mui/material'
import ConfirmSignal from 'app/components/ConfirmSignal/ConfirmSignal'
import WelcomeBack from 'app/components/Dashboard-welcomeback/WelcomeBack'
import LiveClock from 'app/components/LiveClock/LiveClock'
import { getLivePrice } from 'app/redux/actions/SignalsActions'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardRadioButton from '../../components/Dashboard-radioButtons/DashboardRadioButtons'
import './NewSignal.css'
function NewSignal() {
    const [pair, setPair] = useState('') //to get the selected pair from the search dropdown
    const [type, setType] = useState('Market Buy') //to get the type of the trade we are publishing
    const [market_price, setMarketPrice] = useState() //to get the market price from input fields in radio buttons
    const [sum, setSum] = useState('') //to set the summary of the signal
    const [oSize, setOSize] = useState('') //to set the order size
    const [selected, setSelected] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [confirmed, setConfirmed] = useState(true)
    const [signalDetails, setSignalDetails] = useState({
        pair: ``,
        order_type: ``,
        market_price: 0,
        order_size: ``,
        summary: ``,
        chart: `image_path`,
        user_id: 0,
        trade_type: `Intraday`,
    })

    const live = useSelector((store) => store.signals.livePrice)
    const sig = useSelector((store) => store.signals)
    const [liveprice, setLiveprice] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (pair) {
            dispatch(getLivePrice(pair))
        }
    }, [pair, live])

    useEffect(() => {
        setLiveprice(live['marketPrice'])
    }, [live])

    return (
        <div>
            {!selected && (
                <div>
                    <WelcomeBack
                        pair={pair}
                        setPair={setPair}
                        setSelected={setSelected}
                        setType={setType}
                        livePrice={liveprice}
                        // username={sig.signals[0].username}
                    />
                </div>
            )}
            {!clicked && selected ? (
                <div className="new_signal_cont">
                    <div className="new_signal_header">
                        <div className="header_title"> New Signal </div>

                        <div className="header_sub">
                            <div></div>
                            <div className="sub_time">
                                <LiveClock />
                            </div>
                        </div>

                        <div className="selected_pair_cont">
                            <div>
                                <div className="selected_pair_title">
                                    Selected Pair
                                </div>
                                <div>{pair} </div>
                            </div>
                            <div>
                                <div className="selected_pair_title">
                                    Live price
                                </div>
                                <div>{liveprice}</div>
                            </div>
                        </div>

                        <div className="radioButton">
                            <DashboardRadioButton
                                onClick={() => {
                                    setClicked(true)
                                    if (
                                        type === 'Market Buy' ||
                                        type === 'Market Sell'
                                    ) {
                                        setMarketPrice(liveprice)
                                    }
                                }}
                                type={type}
                                market_price={market_price}
                                setType={setType}
                                setMarketPrice={setMarketPrice}
                                pair={pair}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                clicked && (
                    <div className="new_signal_cont">
                        <div className="new_signal_header">
                            <div className="header_title"> New Signal </div>

                            <div className="header_sub">
                                <div className="sub_username"></div>
                                <div className="sub_time">
                                    <LiveClock />
                                </div>
                            </div>
                        </div>

                        <div>
                            {confirmed && (
                                <ConfirmSignal
                                    onClick={() => setConfirmed(false)}
                                    confirmation={confirmed}
                                    pair={pair}
                                    type={type}
                                    price={market_price}
                                    orderSize={oSize}
                                    summary={sum}
                                    setSignalDetails={setSignalDetails}
                                    signalDetails={signalDetails}
                                />
                            )}
                            {!confirmed && (
                                <ConfirmSignal
                                    confirmation={confirmed}
                                    pair={pair}
                                    type={type}
                                    price={market_price}
                                    orderSize={oSize}
                                    summary={sum}
                                />
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    )
}

export default NewSignal
